body {
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
}

:root,
body,
html {
  background-color: #f5f5f5;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
header,
html,
i,
img,
label,
li,
nav,
p,
small,
span,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  form {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
